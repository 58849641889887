/* Blocks */
p {
	margin: 0;
}

.lk {
	padding: 160px 0 80px 0;
	flex-grow: 1;
}

@media (min-width: 768px) {
	.lk {
		padding: 230px 0 80px 0;
	}
}

@media (max-width: 360px) {
	.lk .container {
		max-width: 1822px;
	}
}

.lk__breadcrumbs {
	display: flex;
	align-items: center;
}

.lk__breadcrumbs span {
	display: inline-block;
	width: 4px;
	height: 8px;
	margin: 0 14px;
	background: url(../../img/lk/span.svg) no-repeat center/cover;
}

.lk__breadcrumbs-item {
	font-weight: 400;
	font-size: 16px;
	line-height: 22px;
	color: #6B7B8E;
}

.lk__main-title {
	font-weight: 600;
	font-size: 36px;
	line-height: 48px;
	color: #0C143B;
	margin: 12px 0 24px 0;
}

@media (min-width: 1200px) {
	.lk__main-title {
		font-size: 42px;
		line-height: 48px;
		margin: 12px 0 32px 0;
	}
}

@media (min-width: 768px) {
	.lk__inner {
		display: grid;
		grid-template-columns: 250px 1fr;
		column-gap: 50px;
	}
}

@media (min-width: 992px) {
	.lk__inner {
		display: grid;
		grid-template-columns: 360px 1fr;
		column-gap: 50px;
	}
}

@media (min-width: 1200px) {
	.lk__inner {
		column-gap: 80px;
	}
}

.lk__aside {
	grid-row: 1/7;
}

@media (min-width: 768px) {
	.lk__nav-list {
		display: flex;
		flex-direction: column;
		background: #F2F4F8;
		padding-bottom: 20px;
	}
}

@media (min-width: 768px) {
	.lk .swiper-slide:not(:first-child) .lk__nav-item {
		border-top: 1px solid #DADEE2;
	}
}

.lk__nav-item {
	font-weight: 700;
	font-size: 14px;
	line-height: 14px;
	letter-spacing: 0.02em;
	text-transform: uppercase;
	color: #405078;
	background: #F2F4F8;
	padding: 13px 16px;
	transition: .5s all ease;
}

.lk__nav-item:hover {
	cursor: pointer;
	background: #DADEE2;
}

.lk__nav-item--active {
	background: #DADEE2;
}

@media (min-width: 768px) {
	.lk__nav-item {
		padding: 16px 20px;
	}
}

@media (min-width: 1200px) {
	.lk__nav-item {
		padding: 24px 30px;
		font-size: 16px;
		line-height: 18px;
	}
}

.lk__nav-item-exit {
	position: relative;
	margin: 0 auto;
	font-weight: 600;
	font-size: 16px;
	line-height: 28px;
	background: #F2F4F8;
	color: #6B7B8E;
	padding: 5px 16px;
	padding-left: 30px;
	display: flex;
	border: 1px solid #BCC7D3;
	border-radius: 6px;
}

.lk__nav-item-exit::before {
	content: '';
	position: absolute;
	top: 50%;
	left: 10px;
	transform: translateY(-50%);
	width: 15px;
	height: 15px;
	background: url(../../img/lk/close.svg) no-repeat center/cover;
}

@media (min-width: 768px) {
	.lk__nav-item-exit::before {
		left: 8%;
		width: 20px;
		height: 20px;
	}
}

@media (min-width: 992px) {
	.lk__nav-item-exit::before {
		left: 20%;
	}
}

@media (min-width: 768px) {
	.lk__nav-item-exit {
		justify-content: center;
		align-items: center;
		padding: 8px 0;
		max-width: 90%;
		margin-top: 20px;
	}
}

@media (min-width: 1200px) {
	.lk__nav-item-exit {
		font-weight: 600;
		font-size: 16px;
		padding: 12px 0;
	}
}

.lk__content {
	margin-top: 25px;
}

@media (min-width: 768px) {
	.lk__content {
		margin-top: 0;
	}
}

.lk__content-wrapper {
	border: 1.5px solid #F2F4F8;
	padding: 20px 12px;
}

@media (min-width: 768px) {
	.lk__content-wrapper {
		padding: 24px;
	}
}

.lk__content-title {
	font-weight: 600;
	font-size: 24px;
	line-height: 32px;
	color: #0C143B;
	margin-bottom: 20px;
}

@media (min-width: 1200px) {
	.lk__content-title {
		font-size: 32px;
		line-height: 40px;
		margin-bottom: 32px;
	}
}

.lk__form {
	display: grid;
	row-gap: 12px;
}

.lk .input-label {
	position: absolute;
	left: 12px;
	top: 13px;
	font-weight: 400;
	font-size: 16px;
	line-height: 22px;
	color: #6B7B8E;
	pointer-events: none;
}

.lk .input-label:focus {
	display: none;
}

.lk .input-label span {
	color: #FE334A;
}

.lk__input {
	position: relative;
}

.lk__input--more-info {
	display: flex;
	flex-direction: column;
}

@media (min-width: 1200px) {
	.lk__input--more-info {
		align-items: center;
		flex-direction: row;
	}
	.lk__input--more-info input {
		margin-right: 20px;
	}
}

.lk__input .error {
	border: 1px solid red;
}

.lk__input input {
	background: #F2F4F8;
	border-radius: 6px;
	width: 100%;
	max-width: 428px;
	border: none;
	padding: 12px;
	font-weight: 400;
	font-size: 16px;
	line-height: 22px;
	transition: .5s opacity ease;
}

.lk__input input::placeholder {
	font-weight: 400;
	font-size: 16px;
	line-height: 22px;
	color: #6B7B8E;
}

.lk__input input:hover {
	opacity: .6;
}

.lk__input input:focus + .input-label {
	display: none;
}

.lk__input input:not(:placeholder-shown) + .input-label {
	display: none;
}

.lk__input textarea {
	background: #F2F4F8;
	border-radius: 6px;
	width: 100%;
	max-width: 428px;
	border: none;
	padding: 12px;
	font-weight: 400;
	font-size: 16px;
	line-height: 22px;
	transition: .5s opacity ease;
	resize: none;
	min-height: 140px;
}

.lk__input textarea::placeholder {
	font-weight: 400;
	font-size: 16px;
	line-height: 22px;
	color: #6B7B8E;
}

.lk__input textarea:hover {
	opacity: .6;
}

.lk__input textarea:focus + .input-label {
	display: none  !important;
}

.lk__input textarea:not(:placeholder-shown) + .input-label {
	display: none !important;
}

@media (min-width: 768px) {
	.lk__input textarea {
		min-height: 180px;
	}
}

.lk__input button {
	font-weight: 600;
	font-size: 16px;
	line-height: 20px;
	color: #FFFFFF;
	padding: 12px 30px;
	background: #FE334A;
	border-radius: 4px;
	border: none;
	margin-top: 20px;
}

.lk__input-more-info {
	font-weight: 400;
	font-size: 14px;
	line-height: 22px;
	color: #6B7B8E;
	margin-top: 8px;
}

@media (min-width: 1200px) {
	.lk__input-more-info {
		margin-top: 0;
		font-size: 16px;
		line-height: 22px;
	}
}

.lk__connection-title {
	font-weight: 600;
	font-size: 18px;
	line-height: 24px;
	color: #000000;
	margin: 60px 0 25px 0;
}

.lk__connection-list {
	display: flex;
	margin-bottom: 30px;
}

.lk__connect-link {
	width: 40px;
	height: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	background: #F2F4F8;
}

.lk__connect-link:hover {
	background: #DADEE2;
}

.lk__connect-link:not(:last-child) {
	margin-right: 12px;
}

.lk__connect-link--active {
	background: #DADEE2;
}

.lk__connect-link-img {
	max-width: 20px;
	max-height: 20px;
}

.lk__connect-form {
	display: grid;
	grid-template-columns: 1fr 125px;
	max-width: 600px;
}

.lk__connect-form input {
	max-width: 100%;
}

.lk__connect-form button {
	margin-top: 0;
	margin-left: 12px;
}

.lk__connect-form .lk__input {
	flex-grow: 2;
}

.lk__connect-form .lk__input:not(:last-child) {
	flex-grow: 1;
}

.lk__form-subtitle {
	font-weight: 600;
	font-size: 18px;
	line-height: 24px;
	margin-bottom: 12px;
}

.lk__checkbox-input {
	appearance: none;
	background: #000;
}

.lk__checkbox-input:checked + .lk__ckeckbox-label::after {
	width: 20px;
	height: 20px;
	left: 2px;
}

.lk__ckeckbox-label {
	position: relative;
	color: #6B7B8E;
	font-weight: 400;
	font-size: 16px;
	line-height: 22px;
	padding-left: 30px;
}

.lk__ckeckbox-label::before {
	content: '';
	position: absolute;
	top: 50%;
	left: 0;
	transform: translateY(-50%);
	width: 24px;
	height: 24px;
	background: #F2F4F8;
	border-radius: 6px;
}

.lk__ckeckbox-label::after {
	content: '';
	position: absolute;
	top: 50%;
	left: 5px;
	transform: translateY(-50%);
	width: 0;
	height: 0;
	background: #FE334A;
	border-radius: 6px;
	transform-origin: 10px 10px;
	transition: .3s all ease;
}

.lk__ragio-btns-group {
	margin-right: 40px;
}

.lk__ragio-input {
	appearance: none;
}

.lk__ragio-input:checked + .lk__ragio-label::after {
	width: 20px;
	height: 20px;
	left: 2px;
}

.lk__ragio-label {
	position: relative;
	color: #6B7B8E;
	font-weight: 400;
	font-size: 16px;
	line-height: 22px;
	padding-left: 30px;
}

.lk__ragio-label::before {
	content: '';
	position: absolute;
	top: 50%;
	left: 0;
	transform: translateY(-50%);
	width: 24px;
	height: 24px;
	background: #F2F4F8;
	border-radius: 50%;
}

.lk__ragio-label::after {
	content: '';
	position: absolute;
	top: 50%;
	left: 5px;
	transform: translateY(-50%);
	width: 0;
	height: 0;
	background: #FE334A;
	border-radius: 50%;
	transform-origin: center;
	transition: .3s all ease;
}

.lk__radio-btns {
	display: flex;
}

.lk__form-btn-reset {
	border: none;
	background: white;
	border-radius: 4px;
	padding: 12px 30px;
	font-weight: 600;
	font-size: 20px;
	line-height: 20px;
	border: 1.5px solid #DADEE2;
	transition: all .5 ease;
}

.lk__form-btn-reset:hover {
	opacity: .7;
}

.lk__form-btn-submit {
	border: none;
	padding: 12px 30px;
	background: #FE334A;
	color: white;
	border-radius: 4px;
	font-weight: 600;
	font-size: 20px;
	line-height: 20px;
	transition: all .5 ease;
}

.lk__form-btn-submit:hover {
	opacity: .7;
}

.lk .message input, .lk .message textarea {
	max-width: 766px;
}

.lk__content-text {
	font-weight: 400;
	font-size: 16px;
	line-height: 130%;
	color: #6B7B8E;
	max-width: 766px;
	margin-bottom: 24px;
}

@media (min-width: 768px) {
	.lk__content-text {
		font-weight: 300;
		font-size: 20px;
		margin-bottom: 32px;
		line-height: 24px;
	}
}

.lk__offers-table {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
}

@media (min-width: 1440px) {
	.lk__offers-table {
		display: block;
	}
}

.lk__offers-table-item {
	display: grid;
}

@media (min-width: 1440px) {
	.lk__offers-table-item {
		grid-template-columns: 150px 1fr 1fr 1fr 150px;
	}
}

.lk__offers-table-head {
	background: #DADEE2;
}

.lk__offers-table-head .lk__offers-unit {
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
}

@media (min-width: 768px) {
	.lk__offers-table-head .lk__offers-unit {
		font-size: 16px;
	}
}

@media (min-width: 1440px) {
	.lk__offers-table-head .lk__offers-unit {
		font-size: 16px;
	}
}

.lk__offers-unit {
	display: flex;
	align-items: center;
	padding: 10px 10px;
}

@media (min-width: 576px) {
	.lk__offers-unit {
		padding: 12px 12px;
	}
}

@media (min-width: 768px) {
	.lk__offers-unit {
		padding: 12px 12px;
	}
}

@media (min-width: 1440px) {
	.lk__offers-unit {
		padding: 15px 20px;
	}
}

.lk__offers-sort {
	flex-shrink: 0;
	display: none;
}

@media (min-width: 1440px) {
	.lk__offers-sort {
		display: flex;
	}
}

.lk__offers-sort img {
	margin-left: 5px;
}

.lk__offers-sort img:hover {
	cursor: pointer;
	opacity: .6;
}

.lk__offers-table-info {
	background: #F2F4F8;
}

.lk__offers-table-info:hover {
	opacity: .8;
}

@media (min-width: 1440px) {
	.lk__offers-table-info {
		max-width: unset;
	}
}

.lk__offers-table-info .lk__offers-unit {
	font-weight: 400;
	font-size: 12px;
	line-height: 100%;
	color: #405078;
}

@media (min-width: 768px) {
	.lk__offers-table-info .lk__offers-unit {
		font-size: 16px;
	}
}

@media (min-width: 1440px) {
	.lk__offers-table-info .lk__offers-unit {
		font-size: 16px;
	}
}

.lk__offers-table-info .lk__offers-unit:not(:last-child) {
	border-bottom: 1px solid #DADEE2;
}

@media (min-width: 1440px) {
	.lk__offers-table-info .lk__offers-unit:not(:last-child) {
		border: none;
	}
}

.lk__offers-edit {
	flex-shrink: 0;
	display: flex;
}

.lk__offers-edit:not(:last-child) {
	margin-right: 10px;
}

.lk__offers-edit img {
	max-width: 20px;
}

@media (min-width: 768px) {
	.lk__offers-edit img {
		max-width: unset;
	}
}

.lk__offers-edit img:hover {
	cursor: pointer;
	opacity: .6;
}

.lk__my-item {
	margin-bottom: 20px;
}

.lk__my-show-hist {
	display: inline-block;
	margin-bottom: 12px;
}

.lk__link {
	font-weight: 400;
	font-size: 16px;
	line-height: 22px;
	color: #13A8FC;
}

.lk__my-content {
	border: 0.5px solid #DADEE2;
}

.lk__my-header {
	font-weight: 700;
	font-size: 16px;
	line-height: 100%;
	padding: 16px 12px;
	color: #405078;
	background: rgba(19, 168, 252, 0.1);
}

@media (min-width: 1200px) {
	.lk__my-header {
		font-size: 20px;
		line-height: 22px;
	}
}

.lk__my-inner {
	padding: 0 12px;
	font-weight: 400;
	font-size: 14px;
	line-height: 22px;
	color: #6B7B8E;
}

.lk__my-inner .lk__link {
	text-decoration: underline;
	font-weight: 400;
	font-size: 14px;
	line-height: 120%;
}

@media (min-width: 1200px) {
	.lk__my-inner .lk__link {
		font-size: 16px;
		line-height: 120%;
	}
}

@media (min-width: 1200px) {
	.lk__my-inner {
		padding: 0 24px;
	}
}

.lk__my-inner-title {
	font-weight: 400;
	font-size: 14px;
	line-height: 109.62%;
	margin: 20px 0 20px 0;
	padding-bottom: 5px;
	border-bottom: 0.5px solid #DADEE2;
	color: #6B7B8E;
	text-transform: uppercase;
}

.lk__my-inner-product-wrapper {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	font-weight: 700;
	font-size: 16px;
	line-height: 100%;
	color: #405078;
	margin-bottom: 15px;
}

.lk__my-inner-product-wrapper p {
	margin-bottom: 10px;
}

@media (min-width: 768px) {
	.lk__my-inner-product-wrapper p {
		margin-right: 10px;
		margin-bottom: 12px;
	}
}

@media (min-width: 992px) {
	.lk__my-inner-product-wrapper p {
		margin-bottom: 0;
	}
}

.lk__my-label {
	display: inline-block;
	font-weight: 700;
	font-size: 14px;
	line-height: 100%;
	padding: 6px 8px;
}

.lk__my-label--loading {
	background: #F2F4F8;
	color: #6B7B8E;
}

@media (min-width: 768px) {
	.lk__my-label {
		font-size: 16px;
	}
}

.lk__my-button {
	display: inline-block;
	border-radius: 4px;
	font-weight: 600;
	font-size: 16px;
	line-height: 20px;
	padding: 10px 24px;
	border: none;
	display: none;
}

@media (min-width: 1440px) {
	.lk__my-button {
		display: inline-block;
	}
}

@media (min-width: 1440px) {
	.lk__my-button {
		margin-left: auto;
	}
}

.lk__my-button--loading {
	background: #DADEE2;
	color: white;
}

.lk__my-label--error {
	color: #FE334A;
	background: rgba(254, 51, 74, 0.1);
}

.lk__my-label--sucess {
	color: #0BAF60;
	background: rgba(11, 175, 96, 0.1);
}

.lk__my-button--mobile {
	display: inline-block;
}

@media (min-width: 1440px) {
	.lk__my-button--mobile {
		display: none;
	}
}

.lk__my-sum {
	margin: 12px 0;
}

.lk__my-descr {
	margin-bottom: 20px;
}

@media (min-width: 1200px) {
	.lk__my-descr {
		margin-bottom: 35px;
	}
}

.lk__my-inner-product-status {
	margin-bottom: 12px;
	display: flex;
	align-items: center;
}

.lk__my-inner-product-status p {
	margin-right: 10px;
}

.lk__my-more-wrapper {
	border-top: 0.5px solid #DADEE2;
	margin-top: 15px;
	padding: 16px 0;
	display: grid;
}

@media (min-width: 768px) {
	.lk__my-more-wrapper {
		display: flex;
		padding: 25px 0;
	}
}

.lk__my-more-info {
	font-weight: 700;
	font-size: 16px;
	text-decoration: underline;
	margin-bottom: 12px;
	color: #13A8FC;
}

@media (min-width: 768px) {
	.lk__my-more-info {
		margin-right: auto;
		font-size: 20px;
		margin-bottom: 0;
	}
}

.lk__my-more-repeat {
	display: flex;
	align-items: center;
	color: #13A8FC;
	margin-bottom: 12px;
}

@media (min-width: 768px) {
	.lk__my-more-repeat {
		margin-bottom: 0;
	}
}

.lk__my-more-repeat:hover img {
	transform: rotate(360deg);
}

.lk__my-more-repeat img {
	transition: 1s all ease;
	margin-right: 10px;
}

.lk__my-more-close {
	text-decoration-line: underline;
	color: #6B7B8E;
}

@media (min-width: 768px) {
	.lk__my-more-close {
		margin-bottom: 0;
		margin-left: 25px;
	}
}

.lk__books-wrapper {
	font-weight: 600;
	font-size: 18px;
	line-height: 24px;
	color: #0C143B;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	column-gap: 20px;
	row-gap: 20px;
}

@media (min-width: 768px) {
	.lk__books-wrapper {
		grid-template-columns: repeat(3, 1fr);
	}
}

@media (min-width: 992px) {
	.lk__books-wrapper {
		font-size: 20px;
		grid-template-columns: repeat(4, 1fr);
	}
}

@media (min-width: 1200px) {
	.lk__books-wrapper {
		grid-template-columns: repeat(5, 1fr);
	}
}

.lk__books-item {
	display: grid;
	row-gap: 12px;
	max-width: 200px;
	margin: 0 auto;
}

@media (min-width: 992px) {
	.lk__books-item {
		row-gap: 15px;
	}
}

.lk__books-item-img {
	box-shadow: 0px 5.13187px 25.6594px 1.53956px rgba(130, 146, 186, 0.45);
}

.lk__book-descr {
	font-weight: 400;
	font-size: 14px;
	line-height: 18px;
}
