@import 'common/_variables';
p{
    margin: 0;
}
.lk {
    padding: 160px 0 80px 0;
    @media (min-width:768px) {
        padding: 230px 0 80px 0;
    }
   
    flex-grow: 1;
    .container{
        @media (max-width:360px) {
            max-width: 1822px;
        }
    }
    // PERSONAL
    // PERSONAL
    // PERSONAL
    // PERSONAL
    .personal{
        
    }
    &__breadcrumbs {
        display: flex;
        align-items: center;
        span{
            display: inline-block;
            width: 4px;
            height: 8px;
            margin: 0 14px;
            background:  url(../../img/lk/span.svg)no-repeat center/cover;
        }
    }

    &__breadcrumbs-item {
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: #6B7B8E;
    }

    &__main-title {
        font-weight: 600;
        font-size: 36px;
        line-height: 48px;
        color: #0C143B;
        margin: 12px 0 24px 0;
        @media (min-width:1200px) {
            font-size: 42px;
            line-height: 48px;
            margin: 12px 0 32px 0;
        }
    }

    &__inner {
        // 
        @media (min-width:768px) {
            display: grid;
            grid-template-columns: 250px 1fr;
            column-gap: 50px;
        }
        @media (min-width:992px) {
            display: grid;
            grid-template-columns: 360px 1fr;
            column-gap: 50px;
        }
        @media (min-width:1200px) {
            column-gap: 80px;
        }
    }

    &__aside {
        grid-row: 1/7;
    }

    &__nav-slider {
    }

    &__nav-list {
        @media (min-width:768px) {
            display: flex;
            flex-direction: column;
            background: #F2F4F8;
            padding-bottom: 20px;
        }
        
    }
    .swiper-slide {
        @media (min-width:768px) {
            &:not(:first-child) .lk__nav-item{
                border-top: 1px solid #DADEE2;
            }
        }
        
        
    }
    &__nav-item {
        font-weight: 700;
        font-size: 14px;
        line-height: 14px;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #405078;
        background: #F2F4F8;
        padding: 13px 16px;
        transition: .5s all ease;
        &:hover{
            cursor: pointer;
            background: #DADEE2;
        }
        &--active{
            background: #DADEE2;
        }

        @media (min-width:768px) {
            padding: 16px 20px;
        }
        @media (min-width:1200px) {
            padding: 24px 30px ;
            font-size: 16px;
            line-height: 18px;
        }
    }

    &__nav-item-exit {
        position: relative;
        margin: 0 auto;
        font-weight: 600;
        font-size: 16px;
        line-height: 28px;
        background: #F2F4F8;
        color: #6B7B8E;
        padding: 5px 16px;
        padding-left: 30px;
        display: flex;
        
        border: 1px solid #BCC7D3;
        border-radius: 6px;
        &::before{
            content: '';
            position: absolute;
            top: 50%;
            left: 10px;
            transform: translateY(-50%);
            width: 15px;
            height: 15px;
            background:  url(../../img/lk/close.svg)no-repeat center/cover;
            @media (min-width:768px) {
                left: 8%;
                width: 20px;
                height: 20px;
            }
            @media (min-width:992px) {
                left: 20%;
  
            }
        }
        @media (min-width:768px) {
            justify-content: center;
            align-items: center;
            padding: 8px 0;
            max-width: 90%;
            margin-top: 20px;
        }
        @media (min-width:1200px) {
            font-weight: 600;
            font-size: 16px;
            padding: 12px 0;
        }
    }

    &__content {
        margin-top: 25px;
        @media (min-width:768px) {
            margin-top: 0;
        }
    }

    &__content-wrapper {
        border: 1.5px solid #F2F4F8;
        padding: 20px 12px;
        @media (min-width:768px) {
            padding: 24px;   
        }
    }

    &__content-title {
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        color: #0C143B;
        margin-bottom: 20px;
        @media (min-width:1200px) {
            font-size: 32px;
            line-height: 40px;
            margin-bottom: 32px;
        }
    }
    &__form{
        display: grid;
        row-gap: 12px;
    }
    .input-label{
        position: absolute;
        left: 12px;
        top: 13px;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: #6B7B8E;
        pointer-events: none;
        &:focus{
            display: none;
        }
        span{
            color: #FE334A;
        }
    }
    &__input {
        position: relative;
        &--more-info{
            display: flex;
            
            flex-direction: column;
            @media (min-width:1200px) {
                align-items: center;
                flex-direction: row;
                input{
                    margin-right: 20px;
                }
            }
            
        }
        .error{
            border: 1px solid red;
        }
        input{
            background: #F2F4F8;
            border-radius: 6px;
            width: 100%;
            max-width: 428px;
            border: none;
            padding: 12px;
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            transition: .5s opacity ease;
            &::placeholder{
                font-weight: 400;
                font-size: 16px;
                line-height: 22px;
                color: #6B7B8E;
            }
            &:hover{
                opacity: .6;
            }
            &:focus + .input-label{
                display: none;
            }
            &:not(:placeholder-shown) + .input-label{
                display: none;
            }
            
        }
        textarea{
            background: #F2F4F8;
            border-radius: 6px;
            width: 100%;
            max-width: 428px;
            border: none;
            padding: 12px;
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            transition: .5s opacity ease;
            resize: none;
            min-height: 140px;
            &::placeholder{
                font-weight: 400;
                font-size: 16px;
                line-height: 22px;
                color: #6B7B8E;
            }
            &:hover{
                opacity: .6;
            }
            &:focus + .input-label{
                display: none  !important;
            }
            &:not(:placeholder-shown) + .input-label{
                display: none !important;
            }
            @media (min-width:768px) {
                min-height: 180px;
            }
        }
        button{
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            color: #FFFFFF;
            padding: 12px 30px;
            background: #FE334A;
            border-radius: 4px;
            border: none;
            margin-top: 20px;
        }
        
    }
    &__input-more-info{
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #6B7B8E;
        margin-top: 8px;
        @media (min-width:1200px) {
            margin-top: 0;
            font-size: 16px;
            line-height: 22px;
        }
        
    }

    &__connection-title {
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        color: #000000;
        margin: 60px 0 25px 0;
    }

    &__connection-list {
        display: flex;
        margin-bottom: 30px;
    }

    &__connect-link {
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background: #F2F4F8;
        &:hover{
            background: #DADEE2;
        }
        &:not(:last-child){
            margin-right: 12px;
        }
        &--active{
            background: #DADEE2;
        }
    }

    &__connect-link-img {
        max-width: 20px;
        max-height: 20px;
        
    }

    &__connect-form {
        display: grid;
        grid-template-columns: 1fr 125px;
        max-width: 600px;
        input{
            max-width: 100%;
        }
        button{
            margin-top: 0;
            margin-left: 12px;
            // max-width: 125px;
        }
        .lk__input{
            flex-grow: 2;
            &:not(:last-child){
                flex-grow: 1;
            }
        }
    }
    // PERSONAL END
    // PERSONAL END
    // PERSONAL END
    // PERSONAL END


    // SETTINGS 
    // SETTINGS 
    // SETTINGS 
    // SETTINGS 
    &__form-subtitle {
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 12px;
    }

    &__checkbox-items {
    }
    &__checkbox-btns-group{
        
    }
    &__checkbox-input{
        appearance: none;
        background: #000;
        
    }
    &__checkbox-input:checked + .lk__ckeckbox-label::after{
        width: 20px;
        height: 20px;
        left:2px;
        
    }
    &__ckeckbox-label{
        position: relative;
        color: #6B7B8E;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        padding-left: 30px;
        &::before{
            content: '';
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            width: 24px;
            height: 24px;
            background: #F2F4F8;
            border-radius: 6px;

        }
        &::after{
            content: '';
            position: absolute;
            top: 50%;
            left: 5px;
            transform: translateY(-50%);
            width: 0;
            height: 0;
            background: #FE334A;
            border-radius: 6px;
            transform-origin: 10px 10px;
            transition: .3s all ease;
        }
    }
    
    &__ragio-btns-group {
        margin-right: 40px;
    }
    &__ragio-input{
        appearance: none;
    }
    &__ragio-input:checked + .lk__ragio-label::after{
        width: 20px;
        height: 20px;
        left:2px;
        
    }
    &__ragio-label {
        position: relative;
        color: #6B7B8E;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        padding-left: 30px;
        &::before{
            content: '';
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            width: 24px;
            height: 24px;
            background: #F2F4F8;
            border-radius: 50%;

        }
        &::after{
            content: '';
            position: absolute;
            top: 50%;
            left: 5px;
            transform: translateY(-50%);
            width: 0;
            height: 0;
            background: #FE334A;
            border-radius: 50%;
            transform-origin: center;
            transition: .3s all ease;
        }
    }

    &__radio-btns {
        display: flex;
    }
   
    &__ragio {
    }
    &____form-btns-group{

    }
    &__form-btn-reset {
        border: none;
        background: white;
        border-radius: 4px;
        padding: 12px 30px;
        font-weight: 600;
        font-size: 20px;
        line-height: 20px;
        // color: black;
        border: 1.5px solid #DADEE2;
        transition: all .5 ease;
        &:hover{
            opacity: .7;
        }
    }
    &__form-btn-submit{
        border: none;
        padding: 12px 30px;
        background: #FE334A;
        color: white;
        border-radius: 4px;
        font-weight: 600;
        font-size: 20px;
        line-height: 20px;
        transition: all .5 ease;
        &:hover{
            opacity: .7;
        }

    }
    // SETTINGS END
    // SETTINGS END
    // SETTINGS END
    // SETTINGS END


    // message
    // message
    // message
    // message
    .message{
        input,textarea{
            max-width: 766px;
        }
        
    }
    &__content-text{
        font-weight: 400;
        font-size: 16px;
        line-height: 130%;
        color: #6B7B8E;
        max-width: 766px;
        margin-bottom: 24px;
        @media (min-width:768px) {
            font-weight: 300;
            font-size: 20px;
            margin-bottom: 32px;
            line-height: 24px;
        }
    }



    // message END
    // message END
    // message END
    // message END


    // offer table
    // offer table
    // offer table
    // offer table


    &__offers-table {
        display: grid;
        grid-template-columns: repeat(2,1fr);
        @media (min-width:1440px) {
            display: block;
        }
    }

    &__offers-table-item {
        display: grid;
        @media (min-width:1440px) {
            grid-template-columns: 150px 1fr 1fr 1fr 150px;
        }
        
    }

    &__offers-table-head {
        background: #DADEE2;
        .lk__offers-unit {
            text-transform: uppercase;
            font-weight: 700;
            font-size: 12px;
            @media (min-width:768px) {
                font-size: 16px;

            }
            @media (min-width:1440px) {
                font-size: 16px;

            }
        }
    }

    &__offers-unit {
        display: flex;
        align-items: center;
        padding: 10px 10px;
        @media (min-width:576px) {
            padding: 12px 12px;
        }
        @media (min-width:768px) {
            padding: 12px 12px;
        }
        @media (min-width:1440px) {
            padding: 15px 20px;
        }
    }

    &__offers-sort {
        flex-shrink: 0;
        display: none;
       
        @media (min-width:1440px) {
            display: flex;
        }
        img{
            margin-left: 5px;
            &:hover{
                cursor: pointer;
                opacity: .6;
            }
        }
    }

    &__offers-table-info {
        background: #F2F4F8;
        // width: 100%;
        // max-width: 360px;
        &:hover{
            opacity: .8;
        }
        @media (min-width:1440px) {
            max-width: unset;
        }
        .lk__offers-unit{
            font-weight: 400;
            font-size: 12px;
            line-height: 100%;
            color: #405078;
           
            @media (min-width:768px) {
                font-size: 16px;
            }
            @media (min-width:1440px) {
                font-size: 16px;
            }
            &:not(:last-child){
                border-bottom: 1px solid #DADEE2;
                @media (min-width:1440px) {
                    border: none;
                }
                
            }
            
        }
       
    }

    &__offers-edit {
        flex-shrink: 0;
        display: flex;
        &:not(:last-child){
            margin-right: 10px;
           
        }
        img{
            max-width: 20px;
            @media (min-width:768px) {
                max-width: unset;
            }
            
            &:hover{
                cursor: pointer;
                opacity: .6;
            }
        }
    }
    // offer table
    // offer table
    // offer table
    // offer table



    // MY
    // MY
    // MY
    // MY

    &__my-item {
        margin-bottom: 20px;
    }

    &__my-show-hist {
        display: inline-block;
        // text-decoration: underline;
        margin-bottom: 12px;
    }

    &__link {
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: #13A8FC;
    }

    &__content-title {
        // font-weight: 400;
        // font-size: 20px;
        // line-height: 24px;
        // color: #0C143B;
    }

    &__my-content {
        border: 0.5px solid #DADEE2;
        
    }

    &__my-header {
        font-weight: 700;
        font-size: 16px;
        line-height: 100%;
        padding: 16px 12px;
        color: #405078;
        background: rgba(19, 168, 252, 0.1);
        @media (min-width:1200px) {
            font-size: 20px;
            line-height: 22px;
            
        }
    }

    &__my-inner {
        padding: 0 12px;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #6B7B8E;
        .lk__link{
            text-decoration: underline;
            font-weight: 400;
            font-size: 14px;
            line-height: 120%;
            @media (min-width:1200px) {
                font-size: 16px;
                line-height: 120%;
            }
        }
        @media (min-width:1200px) {
            padding: 0 24px;
            
        }

    }

    &__my-inner-title {
        font-weight: 400;
        font-size: 14px;
        line-height: 109.62%;
        margin: 20px 0 20px 0;
        padding-bottom: 5px;
        border-bottom: 0.5px solid #DADEE2;
        color: #6B7B8E;
        text-transform: uppercase;
        
        
    }

    &__my-inner-product-wrapper {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        font-weight: 700;
        font-size: 16px;
        line-height: 100%;
        color: #405078;
        margin-bottom: 15px;
        p{
            margin-bottom: 10px;
            @media (min-width:768px) {
                margin-right: 10px;
                margin-bottom: 12px;
            }
            
            @media (min-width:992px) {
                
                margin-bottom: 0;
            }
        }
        

    }

    &__my-label {
        display: inline-block;
        font-weight: 700;
        font-size: 14px;
        line-height: 100%;
        padding: 6px 8px;
       
        &--loading{
            background: #F2F4F8;
            color: #6B7B8E;
        }
        @media (min-width:768px) {
            font-size: 16px;
        }
    }

    &__my-label--loading {
    }

    &__my-button {
        display: inline-block;
        border-radius: 4px;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        padding: 10px 24px;
        border: none;
        display: none;
        @media (min-width:1440px) {
            display: inline-block;
            
        }
        @media (min-width:1440px) {
            margin-left: auto;
            
        }
    }

    &__my-button--loading {
        background: #DADEE2;
        color: white;
    }
    &__my-label--error {
        color: #FE334A;
        background: rgba(254, 51, 74, 0.1);
    }
    &__my-label--sucess {
        color: #0BAF60;
        background: rgba(11, 175, 96, 0.1);
    }
    &__my-button--mobile {
        display: inline-block;
        @media (min-width:1440px) {
            display: none;
        }
      
    }

    &__my-sum {
        margin: 12px 0;
    }

    &__my-descr {
        margin-bottom: 20px;
        @media (min-width:1200px) {
            margin-bottom: 35px;
        }
    }



    &__my-inner-product-status {
        margin-bottom: 12px;
        display: flex;
        align-items: center;
        p{
            margin-right: 10px;
        }
        
    }

    &__my-more-wrapper {
        border-top: 0.5px solid #DADEE2;
        margin-top: 15px;
        padding: 16px 0;
        display: grid;
        @media (min-width:768px) {
            display: flex;  
            padding: 25px 0; 
        }
    }

    &__my-more-info {
        font-weight: 700;
        font-size: 16px;
        text-decoration: underline;
        margin-bottom: 12px;
        color: #13A8FC;
        @media (min-width:768px) {
            margin-right: auto;
            font-size: 20px;
            margin-bottom: 0;
        }
    }

    &__my-more-repeat {
        display: flex;
        align-items: center;
        // padding-left: 20px;
        color: #13A8FC;
        margin-bottom: 12px;
        @media (min-width:768px) {
            
            margin-bottom: 0;
        }
        &:hover{
            img{
                transform: rotate(360deg);
            }
        }
        img{
            transition: 1s all ease;
            margin-right: 10px;
        }
        
    }

    &__my-more-close {
        
        text-decoration-line: underline;
        color: #6B7B8E;
        @media (min-width:768px) {
            margin-bottom: 0;
            margin-left: 25px;
        }
    }

    // books
    // books
    // books
    // books
    // books
    // books

    &__books-wrapper {
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        color: #0C143B;

        display: grid;
        grid-template-columns: repeat(2,1fr);
        column-gap: 20px;
        row-gap: 20px;
        @media (min-width:768px) {
            grid-template-columns: repeat(3,1fr);
        }
        @media (min-width:992px) {
            font-size: 20px;
            grid-template-columns: repeat(4,1fr);
        }
        @media (min-width:1200px) {
            grid-template-columns: repeat(5,1fr);
        }
    }

    &__books-item {
        display: grid;
        row-gap: 12px;
        max-width: 200px;
        margin: 0 auto;
        @media (min-width:992px) {
            row-gap: 15px;
        }
    }

    &__books-item-img {
        box-shadow: 0px 5.13187px 25.6594px 1.53956px rgba(130, 146, 186, 0.45);
    }

    &__book-price {
        
    }

    &__book-author {
    }

    &__book-descr {
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
    }

    

}

